/* Header styles */
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: transparent;
  padding: 20px;
  display: flex;
   
  justify-content: space-evenly;
  align-items: center;
}

.header.scrolled {
  background-color: whitesmoke;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 300px;
}


.logo img {
  min-width: 100px;
  width: 60%;
}

.nav__links {
  list-style: none;
  display: flex;
  font-size: 16px;
}

.nav__links li {
  transition: transform 0.3s ease; 
  
}

.nav__links li:hover {
  transform: scale(1.1);
  color: rgb(122, 35, 163); /* Adjust the scale factor as needed */
}

.nav__links li {
  margin-right: 20px;
}

.nav__links a {
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease;
}

.nav__links a:hover {
  color: #8c36d8;
}

/* Buttons section */
.buttons {
  display: flex;
  align-items: center;
}

.login-btn,
.header-btn {
  margin-left: 20px;
  padding: 8px 16px;
  border: 1px solid #7c099f;
  border-radius: 4px;
  text-decoration: none;
  color: #7c099f;
  position: relative;
  overflow: hidden;
  perspective: 1000px;
  transition: color 0.3s ease, box-shadow 0.3s ease;
}

.login-btn:hover,
.header-btn:hover {
  color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Pulsating shadow on hover */
  transform: rotateX(5deg) rotateY(5deg) translateZ(10px); /* 3D tilt effect on hover */
}

.login-btn::before,
.header-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #b73aff, #8e44ad);
  border-radius: 4px;
  z-index: -1;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 50%;
  opacity: 0;
}

.login-btn:hover::before,
.header-btn:hover::before {
  transform: scaleX(1);
  opacity: 1;
}

.login-btn::after,
.header-btn::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  z-index: -1;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 0 50%;
  opacity: 0;
}

.login-btn:hover::after,
.header-btn:hover::after {
  transform: scaleX(1);
  opacity: 1;
}

.login-btn span,
.header-btn span {
  position: relative;
  z-index: 1;
  transition: transform 0.3s ease-out;
}

.login-btn:hover span,
.header-btn:hover span {
  transform: translateY(-3px);
}

/* Dynamic gradient change on hover */
.login-btn:hover {
  background: linear-gradient(45deg, #704298, #8e44ad);
  box-shadow: 0 20px 40px rgba(202, 58, 255, 0.4); /* Subtle glow on hover */
}

.header-btn:hover {
  background: linear-gradient(45deg, #b297ec, #8d2ecc);
  box-shadow: 0 20px 40px rgba(149, 52, 219, 0.4); /* Subtle glow on hover */
}

/* Header styles */
header {
  display: flex;
  padding: 1.5rem 3rem;
  justify-content: space-around;
  align-items: center;
}

.nav__links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.nav--link {
  position: relative;
  margin-right: 20px;
  cursor: pointer;
}

.nav--link.hover .submenu {
  display: block;
}

.submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  background-color:white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px 0;
  border-radius: 10px;
  width: auto; 
  min-width: 500px;
  margin-top: 10px;

  padding: 10px;
  text-align: center;
}

.submenu::before {
  content: '';
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 15px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}

.submenu li {
  list-style: none;
  display: inline-block; 
  margin: 10px; 
}

.submenu a {
  text-decoration: none;
  color: #333;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}

.submenu a:hover {
  color: #8c36d8;
}

.submenu-content {
  display: flex;
  align-items: center;
  text-align: left;
}

.submenu-icon {
  max-width: 40px;
  max-height: 40px;
  margin-right: 8px;
  border-radius: 50%;
  flex-shrink: 0; /* Prevent shrinking of the icon */
}

.submenu-text-content {
  display: flex;
  flex-direction: column; /* Display description below the name */
}

.submenu-name {
  font-size: 16px;
  font-weight: bold; /* Adjust font weight as needed */
}

.submenu-description {
  font-size: 10px; /* Adjust the font size as needed */
  color: #555; /* Adjust the color as needed */
}

.submenu-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.submenu-item-container {
  display: inline-block;
  margin: 0 10px;
  display: flex;
  align-items: center;   
  transition: transform 0.3s ease, border-radius 0.3s ease, box-shadow 0.3s ease; /* Add transition for a smooth effect */

 margin: 20px;
  cursor: pointer;
}

/* Media queries and responsive styles */
@media (max-width: 1200px) {
  .header {
    padding: 15px;
  }

  .logo {
    width: 150px;
  }

  .logo img {
    width: 100%;
  }

  .nav__links li,
  .nav--link {
    margin-right: 15px;
  }

  .nav__links {
    font-size: 12px;
  }

  .submenu {
    padding: 15px 0;
    margin-top: 5px;
  }

  .submenu li {
    margin: 5px;
  }

  .submenu a {
    font-size: 12px;
  }

  .submenu-icon {
    max-width: 30px;
    max-height: 30px;
    margin-right: 6px;
  }

  .login-btn,
  .header-btn {
    margin-left: 10px;
    margin-right: 10px;
    padding: 8px 12px;
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 10px;
  }

  .logo {
    width: 150px;
  }

  .logo img {
    width: 100%;
  }

  .nav__links li,
  .nav--link {
    margin-right: 10px;
  }

  .nav__links {
    font-size: 10px;
  }

  .submenu {
    padding: 10px 0;
    margin-top: 5px;
  }

  .submenu li {
    margin: 5px;
  }

  .submenu a {
    font-size: 12px;
  }

  .submenu-icon {
    max-width: 24px;
    max-height: 24px;
    margin-right: 4px;
  }

  .login-btn,
  .header-btn {
    margin-left: 8px;
    padding: 8px 10px;
    font-size: 10px;
  }
}


.googleAuthButton {
  display: flex;
  align-items: center;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
}

.googleAuthButton {
  background: linear-gradient(to right, #8c36d8, #3b14a3);
  color: #ffffff;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.googleAuthButton:hover {
  background: linear-gradient(to right, #7e17d8, #2e0e89);
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}



.AuthButton-google-icon {
  margin-right: 12px;
  font-size: 24px;
}

/* Optional: Add a subtle animation on hover for the icon */
.AuthButton:hover .AuthButton-google-icon {
  animation: bounce 0.3s ease;
}



.header-profile {
  position: relative;
  margin-left: 20px;
}

.header-profile-image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: transform 0.3s ease; /* Add a smooth transform effect on hover */
}

.header-profile:hover .header-profile-image {
  transform: scale(1.1); /* Increase size on hover for visual feedback */
}

.profile-dropdown {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  display: none;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 10px;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease; /* Add a smooth fade-in effect */
}

.header-profile:hover .profile-dropdown {
  display: flex;
  opacity: 1;
}

.profile-dropdown::before {
  content: '';
  position: absolute;
  top: -18px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 12px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}

.profile-dropdown a {
  text-decoration: none;
  color: #333;
  padding: 10px;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

.profile-dropdown a:hover {
  background-color: #f0f0f0;
  color: #8c36d8;
}

.profile-dropdown button {
  border: none;
  background: none;
  color: #e74c3c;
  padding: 10px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.profile-dropdown button:hover {
  color: #c0392b;
}
