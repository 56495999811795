/* Updated styles with decreased font sizes */

.second-footer {
  background-color: #333;
  color: white;
  padding: 20px 0; /* Decreased padding */
  margin-bottom: 30px; /* Decreased margin-bottom */
  transition: margin-bottom 0.5s ease;
}

.second-footer.no-margin {
  margin-bottom: 0;
}

.second-footer .copyright-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 10px; /* Decreased padding */
}

.second-footer .copyright-content {
  display: flex;
  justify-content: space-between;
}

.second-footer .copyright-list {
  width: 70%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.second-footer .copyright-list li {
  margin-right: 10px; /* Decreased margin-right */
  font-size: 12px; /* Decreased font size */
}

.second-footer .copyright-text {
  width: 30%;
  text-align: right;
  font-size: 12px; /* Decreased font size */
}

.second-footer p {
  margin: 0;
  font-size: 12px; /* Decreased font size */

  @media screen and (min-width: 576px) {
    font-size: 6px; /* Decreased font size */
  }

  @media screen and (min-width: 768px) {
    font-size: 8px; /* Decreased font size */
  }

  @media screen and (min-width: 992px) {
    font-size: 10px; /* Decreased font size */
  }

  @media screen and (min-width: 1200px) {
    font-size: 12px;
  }
}

.second-footer .copyright-text {
  width: 30%;
  text-align: right;
  font-size: 12px; /* Decreased font size */
}
.copyright-text-hover {
  text-decoration: none;
  color: #ffffff;
}
.copyright-text-hover:hover {
  color: #a58cf4;
  transition: color 0.3s ease;
  cursor: pointer;
}



@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.announcement-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #8c36d8;
  color: #ffffff;
  padding: 8px; /* Decreased padding */
  font-size: 0.9rem; /* Decreased font size */
  border-top: 1px solid #ffffff;
  z-index: 25;
  max-height: 50px; /* Decreased max-height */
  transition: opacity 0.5s ease;
}

.announcement-bar.fade-out {
  opacity: 0;
}

.announcement-bar-container {
  display: flex;
  align-items: center;
  margin: auto;
  height: 100%;
}

.announcement-content {
  text-decoration: underline;
  color: #ffffff;
  display: flex;
  align-items: center;
  transition: color 0.3s ease, transform 0.3s ease;
}

.announcement-content:hover {
  color: #000000;
  transform: scale(1.05);
}

.announcement-icon {
  margin-right: 8px; /* Decreased margin-right */
  animation: ringing 0.7s infinite;
  font-size: 18px; /* Decreased font size */
}

@keyframes ringing {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.close-icon {
  margin-left: 8px; /* Decreased margin-left */
  font-size: 20px; /* Decreased font size */
  cursor: pointer;
  border-radius: 50%;
  transition: transform 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease;
}

.close-icon:hover {
  transform: scale(1.2);
  opacity: 0.8;
  border-radius: 50%;
  box-shadow: 0 0 8px #8c36d8, 0 0 16px #8c36d8, 0 0 24px #8c36d8; /* Decreased box-shadow sizes */
}

.announcement-bar p {
  margin: 0;
  cursor: pointer;
}
