.mob-subtopic-details-main {
  margin-top: 10%;
  margin-bottom: 10%;
}

.mob-subtopic-detailsContentIntro-container {
  text-align: center;
  margin-bottom: 10%;
  padding: 5%;
}

.mob-subtopic-detailsContentIntro-heading {
  color: #000000;
  font-size: 2.5em;
  margin-bottom: 10px;
  max-width: 100%;
}

.mob-subtopic-detailsContentIntro-paragraph {
  font-size: 1.2em;
  text-align: center;
  line-height: 1.5;
  color: #4a4949;
  margin-bottom: 20px;
  max-width: 100%;
}

.mob-subtopic-details-row-fluid {
  display: flex;
  flex-direction: column;
  padding: 5%;
}

/* Define a background color for even rows */
.mob-subtopic-details-row-fluid:nth-child(even) {
  background: linear-gradient(
    90deg,
    #f6f3ff 0%,
    rgba(159, 66, 226, 0.214) 100%
  );
}

.mob-subtopic-details-col {
  flex: 1;
}

.mob-subtopic-details-text-col {
  padding: 10px;
}

.mob-subtopic-details-text-col h1 {
  color: #8c36d8;
  font-size: 2em;
}

.mob-subtopic-details-paragraph {
  font-size: 1em;
  text-align: justify;
  line-height: 1.5;
  color: #333;
  margin-bottom: 5px;
}

.mob-subtopic-details-image-col {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.mob-subtopic-details-image-col img {
  max-width: 100%;
  height: auto;
}

.mob-subtopic-details-text {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Media query for screens up to 576px (typical small mobile devices) */
@media only screen and (max-width: 576px) {
  .mob-subtopic-detailsContentIntro-heading {
    font-size: 1.8em;
  }

  .mob-subtopic-detailsContentIntro-paragraph {
    font-size: 1em;
  }

  .mob-subtopic-details-text-col h1 {
    font-size: 1.5em;
  }

  .mob-subtopic-details-paragraph {
    font-size: 0.9em;
  }

  .mob-subtopic-details-row-fluid {
    padding: 10px;
  }
}

/* Media query for screens up to 768px (typical tablet devices) */
@media only screen and (max-width: 768px) {
  .mob-subtopic-detailsContentIntro-heading {
    font-size: 2em;
  }

  .mob-subtopic-details-text-col h1 {
    font-size: 1.8em;
  }

  .mob-subtopic-details-paragraph {
    font-size: 1em;
  }
}
