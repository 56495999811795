.PlainLanding-container {
  display: flex;
  justify-content: center;
  padding: 10%;
  background: linear-gradient(rgb(7, 6, 36) -17.19%, rgba(7, 6, 36, 0) 111.4%),
    linear-gradient(86.97deg, rgba(255, 168, 0, 0.4) -0.67%, rgba(25, 187, 125, 0.4) 24.91%, rgba(0, 82, 204, 0.4) 48.82%, rgba(69, 164, 236, 0.4) 72.18%, rgba(184, 95, 255, 0.4) 88.31%),
    rgb(7, 6, 36);
}

.PlainLanding-text-col {
  padding: 20px;
  text-align: center;
}

.PlainLanding-text-col h1 {
  /* color: #8c36d8; */
  color: white;
  font-size: 5em;
}

@media only screen and (max-width: 768px) {
  .PlainLanding-text-col h1 {
    font-size: 2em;
  }
}

@media only screen and (max-width: 1024px) {
  .PlainLanding-text-col h1 {
    font-size: 3em;
  }

  .PlainLanding-row-fluid {
    padding: 5%;
    margin-top: 20px;
  }

  .PlainLanding-text-col {
    padding: 10px;
  }
}
