/* Base styles for all screen sizes */

.HomeLanding-main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10%;
}

.HomeLanding-col {
  flex: 1;
}

.HomeLanding-text-container {
  padding: 20px;
}

.HomeLanding-text-container h1 {
  color: #8c36d8;
  font-size: 2em;
}

.HomeLanding-praragraph {
  font-size: 1em;
  text-align: justify;
  line-height: 1.5;
  color: #333;
  margin-bottom: 5px;
}

.HomeLanding-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.HomeLanding-image-container img {
  max-width: 100%;
  height: auto;
}

.HomeLanding-button {
  display: inline-block;
  padding: 12px 24px;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: #8c36d8;
  color: #fff;
  border-radius: 10px;
  transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
}

.HomeLanding-button:hover {
  background-color: #6b258b;
  transform: scale(1.05);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}



.HomeLanding-card-container {
  display: flex;
  flex-direction: row;
}

.HomeLanding-card {
  height: 220px;
  padding: 10px;
  margin: 10px;
  width: 100%;
  transition: transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out, border-radius 0.3s ease-in-out;
}

.HomeLanding-card:hover {
  transform: scale(1.02);
  box-shadow: -4px 4px 25px 0px rgba(0, 0, 0, 0.075);
  border-radius: 20px;
  padding: 4%;
}

.HomeLanding-card h2 {
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  color: #333;
  font-size: 1.5em;
}

.HomeLanding-card:hover h2 {
  color: #8c36d8;
  transform: translateY(-10px);
  
}

.HomeLanding-card p {
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  color: #666;
  font-size: 0.9em;
  

}

.HomeLanding-card:hover p {
  color: #000000;
  transform: translateY(-5px);
}
@media only screen and (max-width: 768px) {
  .HomeLanding-card {
    padding: 15px;
    margin: 15px;
  }

  .HomeLanding-card h2 {
    font-size: 0.7em;
  }

  .HomeLanding-card p {
    font-size: 0.5em;
  }
}

/* Responsive styles for medium-sized screens (e.g., tablets) */

@media only screen and (max-width: 1200px) {
  .HomeLanding-card {
    padding: 15px;
    margin: 10px;
  }

  .HomeLanding-card h2 {
    font-size: 1em;
  }

  .HomeLanding-card p {
    font-size: 0.7em;
  }
}
/* Responsive styles for font sizes */

@media only screen and (max-width: 768px) {
  .HomeLanding-text-container h1 {
    font-size: 1em;
  }

  .HomeLanding-praragraph {
    font-size: 0.5em;
  }

  .HomeLanding-button {
    font-size: 0.9em;
  }
}

/* Responsive styles for small desktop and tablets */

@media only screen and (max-width: 1024px) {
  .HomeLanding-text-container h1 {
    font-size: 1em;
  }

  .HomeLanding-praragraph {
    font-size: 0.7em;
  }
  .HomeLanding-button {
    font-size: 0.7em;
    padding: 8px 15px;
  }
  .HomeLanding-main {
    padding: 5%;
    margin-top: 20px;
  }

  .HomeLanding-text-container {
    padding: 10px;
  }

  .HomeLanding-image-container {
    margin-bottom: 20px;
  }

  .HomeLanding-card {
    width: 48%; 
  }
}
