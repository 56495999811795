/* Base styles */

.popup-subscribe-main {
  background: linear-gradient(rgb(240, 240, 255), rgba(240, 240, 255, 0)),
  linear-gradient(
    86.97deg,
    rgba(255, 218, 185, 0.4) -0.67%,
    rgba(204, 238, 220, 0.4) 24.91%,
    rgba(198, 227, 255, 0.4) 48.82%,
    rgba(225, 216, 255, 0.4) 72.18%,
    rgba(255, 198, 227, 0.4) 88.31%
  ),
  rgb(240, 240, 255);
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 700px;
  height: 70vh;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  z-index: 50;
  border-radius: 10px;
  overflow: hidden;
}

/* Add styles for the welcome message */

.popup-welcome-div {
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #8c36d8, #5c1a82); /* Gradient background color */
  color: #fff; /* Text color for the welcome message */
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  /* Advanced styles */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Box shadow for depth effect */
 
  padding: 30px; /* Increased padding for better spacing */
}

.popup-welcome-text {
  font-size: 24px; /* Size for "Welcome to" */
}

.popup-company-text {
  font-size: 36px; /* Size for the company name */
  font-weight: bold;
  margin-top: 5px;
}

/* Add styles for additional content */

.popup-content {
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.popup-close {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #333;
  transition: transform 0.3s ease;
}

.popup-close:hover {
  color: #8c36d8;
  transform: rotate(90deg);
}

.popup-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.popup-content p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #555;
}

.popup-content input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
}

.popup-content button {
  padding: 10px;
  background: linear-gradient(45deg, #8c36d8, #5c1a82);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out, color 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.popup-content button:hover {
  background: linear-gradient(45deg, #7d2fbf, #4d176b);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  color: #ffffff;
}

.popup-content button span {
  display: inline-block;
  transform-origin: 50% 100%;
  animation: bounce 1s ease infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

/* Media queries for responsiveness */

/* For screens up to 1400px */
@media (max-width: 1400px) {
  .popup-subscribe-main {
    width: 90%;
  }
}

/* For screens up to 1200px */
@media (max-width: 1200px) {
  .popup-subscribe-main {
    width: 95%;
  }
}

/* For screens up to 900px */
@media (max-width: 900px) {
  .popup-subscribe-main {
    width: 70%;
    height: 80vh;
  }

  .popup-welcome-div,
  .popup-content {
    width: 100%;
    border-radius: 0;
  }

  .popup-welcome-div {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .popup-content h2 {
    font-size: 20px;
  }
}

/* For screens up to 768px */
@media (max-width: 768px) {
  .popup-subscribe-main {
    width: 60%;
    height: 100vh;
  }

  .popup-welcome-div,
  .popup-content {
    width: 100%;
    border-radius: 0;
  }

  .popup-welcome-div {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .popup-content {
    padding: 15px;
  }

  .popup-content h2 {
    font-size: 18px;
  }

  .popup-content p {
    font-size: 14px;
  }

  .popup-content input,
  .popup-content button {
    font-size: 14px;
  }
}
