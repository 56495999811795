.logoloader-main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(238, 174, 202);
    background: radial-gradient(circle, rgb(255, 255, 255) 0%, rgb(198, 100, 243) 100%);
    /* background: linear-gradient(to bottom right, #ceaee9, #cee1f8, #f0d1d1); */
    z-index: 9999;
  }
  
  .logoLoader {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: linear-gradient(#e0aff3, #9d00ff);
    animation: rotate 3s linear infinite;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .logoLoader span {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(#ffffff, #a037e6);
    animation: pulse 7s linear infinite alternate, spin 5s linear infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .logoLoader span:nth-child(1) {
    filter: blur(5px);
    animation-delay: 0s;
  }
  .logoLoader span:nth-child(2) {
    filter: blur(10px);
    animation-delay: 1s;
  }
  .logoLoader span:nth-child(3) {
    filter: blur(15px);
    animation-delay: 2s;
  }
  .logoLoader span:nth-child(4) {
    filter: blur(20px);
    animation-delay: 3s;
  }
  
  .logoLoader:after {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background: #ffffff;
    border-radius: 50%;
    background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.6) 0%, transparent 80%);
    animation: lightning 3s ease infinite, rotate 5s linear infinite;
  }
  
  @keyframes lightning {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .logoloader-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    animation: flip 3s ease infinite;
  }
  
  .logoloader-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), transparent);
    border-radius: 50%;
  }
  
  @keyframes flip {
    0% {
      transform: translate(-50%, -50%) rotateY(0deg);
    }
    50% {
      transform: translate(-50%, -50%) rotateY(180deg);
    }
    100% {
      transform: translate(-50%, -50%) rotateY(360deg);
    }
  }
  