.footer {
  background-color: #fff;
  border-top: 1px solid #a58cf4;
  color: black;
  padding: 20px 0;
}

.footer-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.footer-row {
  display: flex;
  justify-content: space-between;
}

ul {
  list-style: none;
  padding: 0;
}

.footer-col {
  width: 30%;
  padding: 0 15px;
}

.footer-col h4 {
  font-size: 1.2rem;
  color: black;
  text-transform: capitalize;
  margin-bottom: 15px;
  font-weight: 500;
  position: relative;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.footer-col h4::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  background-color: #a58cf4;
  width: 1.5em;
  height: 0.1em;
}

.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}

.footer-col ul li a {
  color: black;
  display: block;
  font-size: 1rem;
  font-weight: 300;
  text-transform: capitalize;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-col ul li a:hover {
  color: #a58cf4;
  padding-left: 0.5em;
  transition: color 0.3s ease, text-shadow 0.3s ease, transform 0.3s ease;
}

.footer-col-2,
.tem-social-i {
  width: 40%;
  padding: 0 15px;
}

.footer-col-2 h4,
.tem-social-i h4,
.subscribe h4 {
  font-size: 1.2rem;
  color: black;
  text-transform: capitalize;
  margin-bottom: 15px;
  font-weight: 500;
  position: relative;
  white-space: nowrap;
  transition: color 0.3s ease, text-shadow 0.3s ease, transform 0.3s ease;
}

.footer-col-2 h4::before,
.tem-social-i h4::before,
.subscribe h4::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  background-color: #a58cf4;
  width: 1.5em;
  height: 0.1em;
}

.footer-col-2 h4:hover,
.tem-social-i h4:hover,
.subscribe h4:hover {
  color: #a58cf4;
  text-shadow: 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.subscribe {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.subscribe input[type='email'] {
  padding: 0.5em;
  border: 0.1em solid #ccc;
  border-radius: 0.4em;
  width: 10em;
  margin-right: 1em;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.subscribe button {
  padding: 0.5em 1em;
  border: none;
  border-radius: 0.4em;
  background-color: #a58cf4;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease, transform 0.3s ease;
}

.subscribe button:hover {
  background-color: white;
  color: #a58cf4;
  border: 0.1em solid #a58cf4;
  transform: scale(1.05);
}

.social-links,
.tem-social-i .social-links {
  display: flex;
}

.social-links a,
.tem-social-i .social-links a {
  color: black;
  background-color: rgba(255, 255, 255, 0.2);
  display: inline-block;
  height: 2.5em;
  width: 2.5em;
  border-radius: 50%;
  text-align: center;
  margin-right: 1em;
  line-height: 2.5em;
  transition: all 0.5s ease;
}

.social-links a:hover,
.tem-social-i .social-links a:hover {
  color: #151515;
  background-color: #fff;
}

.social-links a svg,
.tem-social-i .social-links a svg {
  color: #333;
  font-size: 1.5em;
}

.social-links a svg:hover,
.tem-social-i .social-links a svg:hover {
  color: #a58cf4;
  transform: scale(1.1);
}

.margin-footer {
  margin-left: 10px;
}

@media screen and (max-width: 1200px) {
  .footer-col,
  .footer-col-2,
  .tem-social-i {
    width: 45%;
  }
}

@media screen and (max-width: 900px) {
  .footer-col,
  .footer-col-2,
  .tem-social-i {
    width: 100%;
    margin-bottom: 20px;
  }
}

.footerdes p{
  font-size: 12px;
  max-width: 70%;
}