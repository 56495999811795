.mobile-contactBar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  /* Add other styles as needed */
}

.mobile-contact-bar {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #6d14bbaf;
  padding: 10px;
  width: 80%;
  border-radius: 20px;
  align-items: center;
  box-shadow: -4px 4px 25px 0px rgba(0, 0, 0, 0.075);
  transition: all 0.3s;
}

.mobile-contact-bar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: -1;
  border-radius: inherit;
  animation: shade 0.3s ease-in-out forwards;
  opacity: 0;
}

@media (hover: hover) {
  .mobile-contact-bar:hover {
    transform: scale(1.05);
    box-shadow: -4px 4px 35px 0px rgba(0, 0, 0, 0.15);
  }
}

.mobile-sub-div {
  padding: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #f7f7f7;
}

.mobile-left-div,
.mobile-right-div {
  flex: 1;
  margin: 10px 0;
}

.mobile-getademo-button {
  padding: 8px 20px;
  border-radius: 10px;
  background-color: #ffffff;
  color: #8c36d8;
  border: none;
  cursor: pointer;
  font-size: 12px;
  box-shadow: -4px 4px 25px 0px rgba(255, 255, 255, 0.075);
  transition: all 0.3s;
}

.mobile-getademo-button:hover {
  background: linear-gradient(90deg, #e4dcfd 0%, #ffffff 100%);
  color: #000000;
  box-shadow: 0 0 10px 1px lightgrey;
  transform: scale(1.05);
  opacity: 0.9;
}
