.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  width: 100%;
  /* margin-bottom: -10px; */
  margin-top: 100px;
  /* margin-left: 20px; */
  position: relative;
}

.service-container {
  padding: 10px;
  width: 80%;
  height: auto;
  border-radius: 40px;
  margin-top: -30px;
  /* overflow: hidden; */
}

.service-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  height: 70px;
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0 0 10px 1px lightgrey;
  z-index: 1;
}

.service-heading1,
.service-heading2 {
  flex: 1;
  text-align: center;
  border-radius: 40px;
  padding: 10px;
  cursor: pointer;
  height: 75%;
}

.service-heading2 {
  margin-right: 10px;
}

.service-heading1 {
  margin-left: 10px;
}

.service-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  border-radius: 20px;
  margin-top: 30px;
  
}

.service-image img {
  max-width: 100%;
  max-height: 80%;
  border-radius: 20px;
}

.subservice-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  height: auto;
  margin-top: 80px;
}

.topic-inactive:hover {
  background-image: linear-gradient(90deg, #E4DCFD 0%, #ffffff 100%);
  color: #000000;
}

.topic-active {
  background: linear-gradient(90deg, #E4DCFD 0%, #ffffff 100%);
  color: #000000;
  box-shadow: 0 0 10px 1px lightgrey;
}

.subservice {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  box-shadow: 0 0 10px 1px lightgrey;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.subservice-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 5px;
}

.subservice-heading div {
  flex: 1;
  height: max-content;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #E4DCFD;
}

.main-subservice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
}

.main-subservice .subservicemain-content {
  flex: 1;
  padding: 20px;
}

.main-subservice .subservicemain-content h3 {
  margin: 5px 0;
  font-size: 24px;
}

.main-subservice .subservicemain-content h6 {
  margin: 10px 0;
  font-size: 16px;
  color: #555;
}

.main-subservice .subservicemain-content a {
  text-decoration: none; /* Remove underline from anchor tags */
}
 /* Normal state styles */
.main-subservice .subservicemain-content a button {
  display: flex;
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #8c36d8;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover state styles */
.main-subservice .subservicemain-content a button:hover {
  background-color: #8c36d8;
  transform: scale(1.05); /* Scale up by 5% on hover */
  box-shadow: 0 0 10px #8c36d8; /* Add a subtle box shadow on hover */
}


.subservice-image-container {
  display: flex;
  margin: 50px;
  margin-bottom: 70px;
  padding: 20px;
}

.service-tem-column {
  flex: 1;
}

.subservice-image {
  display: flex;
  
  max-height: 100px;
  justify-content: center;
  align-items: center;
  height: 100px;
  border-radius: 20px;
  margin-bottom: 30px;
  margin: 20px;
  padding-bottom: 10px;
}

.subservice-image img {
  min-width: 100%;
  min-height: 80%;
  max-height: 350px;
  max-width: 350px;
  border-radius: 5px;
}

.service-heading.selected {
  cursor: pointer;
  color: #000000;
  padding: 5px;
  /* border-bottom: 5px solid #E4DCFD;
   */
   border-bottom: 5px solid #8c36d8;
  font: black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.service-topic1,
.service-topic2 {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.service-topic1.slide-in,
.service-topic2.slide-in {
  opacity: 1;
  transform: translateX(0);
}

.slide-in {
  transform: translateX(100%);
  opacity: 0;
  transition: transform 0.3s ease-in, opacity 0.3s ease-in;
}

.service-container .main-subservice {
  animation: slideIn 0.3s ease-in forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Media Query for mobile view */
@media (max-width: 1200px) {
  .service-heading-container {
    flex-direction: column;
    height: auto;
    overflow: hidden;
  }

  .service-heading1,
  .service-heading2 {
    flex: none;
    width: 100%;
    height: 70px;
    margin-bottom: 10px;
  }

  .service-heading-container .service-heading1,
  .service-heading-container .service-heading2 {
    transform: translateX(0);
    opacity: 1;
  }

  .service-heading1.slide-out,
  .service-heading2.slide-out {
    transform: translateX(-100%);
    opacity: 0;
  }
}



/* @media (min-width: 1400px) {
  .center-container {
    margin-left: 0; 
  }
} */

/* Media Query for mobile view */
@media (max-width: 800px) {
  .center-container {
    
    margin-bottom: 50px;
    margin-top: 20px;
  }
}
@media (max-width: 1200px) {
  .center-container {
    margin: 0;
    margin-bottom: 50px;
    margin-top: 20px;
  }
  .center-container {
    margin-left: 0;
  }
  .service-container {
   
    width: 90%;
    
    border-radius: 20px;
    margin-top: 15px;
    padding: 0px; /* Adjust padding for smaller screens */
  }

  .service-heading-container {
 
     width: 60%;
    height: 80px;
    flex-direction: row;
  }

  .service-heading1,
  .service-heading2 {
    width: 45%;
    height: auto; /* Adjust height for smaller screens */
    margin-top: 10px;
  }

  .service-image {
    margin-top: 20px;
  }

  .subservice-container {
    width: 100%;
    height: auto;
    margin-top: 20px;
    
  }

  .subservice-content{
   height: 1500px;
   
  }
  .subservice-image {
    display: flex;
   height: auto;
    max-height: 50px;
    justify-content: center;
    align-items: center;
   
    width: 250px;
    border-radius: 20px;
   
    box-shadow: #000000;
  }
  .service-heading.selected {
    padding: 10px; /* Adjust padding for selected item */
  }

  .main-subservice {
    margin: 10px;
    padding: 15px;
    border-radius: 10px;
  }

  .main-subservice .subservicemain-content h1 {
    font-size: 24px; /* Adjust font size */
  }

  .main-subservice .subservicemain-content h3 {
    font-size: 20px; /* Adjust font size */
  }

  .main-subservice .subservicemain-content h6 {
    font-size: 14px; /* Adjust font size */
  }

  .main-subservice .subservicemain-content a button {
    padding: 12px 24px; /* Adjust padding for buttons */
    font-size: 16px; /* Adjust font size */
  }
}

