/* ClientSlider container */
.client-slider {
  width: 90%;
  margin: 0 auto;
}

.client-slide {
  text-align: center;
}

.client-slide img {
  max-width: 100%;
  height: auto;
  max-height: 100px; 
  padding: 10px;
}

.client-slider h2 {
  font-size: 30px;
  color: #333;
  margin-bottom: 40px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.client-slider img {
  height: 100px;
  width: 150px;
}
