/* mobile-counter.css */

.mobile-counter-item {
  margin-bottom: 30px;
}

.mobile-counter-number {
  font-size: 2.5rem;
  font-weight: bold;
  color: #8300ee;
  font-family: "Proxima Nova", sans-serif;
}

.mobile-counter-text {
  font-size: 1.4rem;
}

@keyframes count-up {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animated-counter-item {
  animation: count-up 2s ease-out;
}

.animated-counter-number {
  animation: count-up 2s ease-out;
}

@media only screen and (max-width: 767px) {
  .mobile-counter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .mobile-counter-item {
    margin-bottom: 20px;
  }

  .mobile-counter-number {
    font-size: 2rem;
  }

  .mobile-counter-text {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-counter-number {
    font-size: 2.8rem;
  }

  .mobile-counter-text {
    font-size: 1.6rem;
  }
}

@media only screen and (min-width: 1024px) {
  .mobile-counter-item {
    margin-bottom: 40px;
  }

  .mobile-counter-number {
    font-size: 3rem;
  }

  .mobile-counter-text {
    font-size: 1.8rem;
  }

  .animated-counter-item {
    animation: count-up 2s ease-out;
  }

  .animated-counter-number {
    animation: count-up 2s ease-out;
  }
}
