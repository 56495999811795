/* Mobile Second Footer styles */

.mobile-second-footer {
  background-color: #333;
  color: white;
  padding: 15px 0;
  margin-bottom: 40px;
  transition: margin-bottom 0.5s ease;
}

.mobile-second-footer.no-margin {
  margin-bottom: 0;
}

.mobile-second-footer .mobile-copyright-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  text-align: center;
}

.mobile-second-footer p {
  margin: 0;
  font-size: 8px;

  @media screen and (min-width: 576px) {
    font-size: 8px;
  }

  @media screen and (min-width: 768px) {
    font-size: 10px;
  }

  
}

.mobile-second-footer p:hover {
  color: #a58cf4;
  transition: color 0.3s ease;
  cursor: pointer;
}

.mobile-second-footer p::before {
  content: '\2022';
  color: #a58cf4;
  margin-right: 5px;
  animation: rotate 2s infinite linear;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.mobile-announcement-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #8c36d8;
  color: #ffffff;
  padding: 10px;
  font-size: 0.7rem;
  border-top: 1px solid #ffffff;
  z-index: 25;
  max-height: 60px;
  transition: opacity 0.5s ease;
}

.mobile-announcement-bar.fade-out {
  opacity: 0;
}

.mobile-announcement-bar-container {
  display: flex;
  align-items: center;
  margin: auto;
  height: 100%;
}

.mobile-announcement-content {
  text-decoration: underline;
  color: #ffffff;
  display: flex;
  align-items: center;
  transition: color 0.3s ease, transform 0.3s ease;
}

.mobile-announcement-content:hover {
  color: #000000;
  transform: scale(1.05);
}

.mobile-announcement-icon {
  margin-right: 10px;
  animation: ringing 0.7s infinite;
  font-size: 20px;
}

@keyframes ringing {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.mobile-announcement-close-icon {
  margin-left: 10px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
  transition: transform 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease;
}

.mobile-announcement-close-icon:hover {
  transform: scale(1.2);
  opacity: 0.8;
  border-radius: 50%;
  box-shadow: 0 0 10px red, 0 0 20px red, 0 0 30px red;
}

.mobile-announcement-bar p {
  margin: 0;
  cursor: pointer;
}
