.contactBar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    /* margin-top: 200px; */
  
  }
  
  .contact-bar {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #8c36d8;
    padding: 10px;
    width: 100%;
    border-radius: 24px;
    align-items: center;
    box-shadow: -4px 4px 25px 0px rgba(0, 0, 0, 0.075);
    transition: all 0.3s;
  }
  
  .contact-bar::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: -1;
    border-radius: inherit;
    animation: shade 0.3s ease-in-out forwards;
    opacity: 0;
  }
  
  @keyframes shade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .contact-bar:hover {
    transform: scale(1.05);
    box-shadow: -4px 4px 35px 0px rgba(0, 0, 0, 0.15);
  }
  
  .contactBar-sub-div {
    padding: 10px;
    text-align: center;

    font-size: 24px;
    font-weight: bold;
    color: #f7f7f7;
  }
  
  .contactBar-message,
  .contactBar-button-container {
    flex: 1;
    margin: 10px 0;
  }
  
  .getademo-button {
    padding: 12px 35px;
    border-radius: 10px;
    background-color: #ffffff;
    color: #8c36d8;
    border: none;
    cursor: pointer;
    font-size: 18px;
    box-shadow: -4px 4px 25px 0px rgba(255, 255, 255, 0.075);
    transition: all 0.3s;
}



.getademo-button:hover {
  color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Pulsating shadow on hover */
  transform: rotateX(5deg) rotateY(5deg) translateZ(10px); /* 3D tilt effect on hover */
}

.getademo-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #b73aff9b, #8c36d8);
  border-radius: 4px;
  z-index: -1;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 50%;
  opacity: 0;
}

.getademo-button:hover::before {
  transform: scaleX(1);
  opacity: 1;
}

.getademo-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  z-index: -1;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 0 50%;
  opacity: 0;
}

.getademo-button:hover::after {
  transform: scaleX(1);
  opacity: 1;
}

.getademo-button span {
  position: relative;
  z-index: 1;
  transition: transform 0.3s ease-out;
  border-radius: 10px;
}

.getademo-button:hover span {
  transform: translateY(-3px);
  border-radius: 10px;

}

/* Dynamic gradient change on hover */
.getademo-button:hover {
  background: linear-gradient(45deg, #704298, #8e44ad);
  box-shadow: 0 20px 40px rgba(203, 194, 194, 0.4); /* Subtle glow on hover */
}



  
  @media (min-width: 1200px) {
    .contact-bar {
      width: 75%;
      flex-direction: row;
      height: 180px;
      justify-content: space-between;
      /* margin-top: 100px; */
    }
  
    .contactBar-sub-div {
      font-size: 28px;
    }
  
    .contactBar-message {
      flex: 7;
    }
  
    .contactBar-button-container {
      flex: 3;
      margin: 0 10px;
    }
  
    
  }
  
  @media (max-width: 1200px) {
    .contact-bar {
      width: 75%;
      flex-direction: row;
      height: 180px;
      justify-content: space-between;
      /* margin-top: 100px; */
    }
  
    .contactBar-sub-div {
      font-size: 20px;
    }
  
    .contactBar-message {
      flex: 7;
    }
  
    .contactBar-button-container {
      flex: 3;
      margin: 0 10px;
    }
  
    .getademo-button {
      font-size: 10px;
    }
  }
  