.mobile-service-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 50px;
}

.mobile-service-heading-container {
    order: 1;
    max-width: 55%;
    height: 70px;
    text-align: center;
    /* background-color: #fff; */
    border-radius: 40px;
    box-shadow: -3.80016px 3.80016px 23.75097px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

  

.mobile-service-heading {
    font-size: 18px;
    font-weight: bold;
    width: 50%;
    flex: 1;
    text-align: center;
    border-radius: 40px;
    padding: 10px;
    cursor: pointer;
    height: 80%;
    transition: background-image 0.3s; /* Add transition for smooth color change */

    /* Default background color */
    background-image: linear-gradient(90deg, transparent 0%, transparent 100%);

    /* Add the selected style */
    &.selected {
        background-image: linear-gradient(90deg, #E4DCFD 0%, #ffffff 100%);
        color: #000000;
        box-shadow: -3.80016px 3.80016px 23.75097px 0px rgba(0, 0, 0, 0.186);
        margin-right: 10px;
        margin-left: 10px;
    }
}

.mobile-service-heading:hover {
    background-image: linear-gradient(90deg, #E4DCFD 0%, #ffffff 100%);
    color: #000000;
}
.collapsible-container {
    order: 2;
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
}

.Collapsible {
    width: 100%; /* Ensure the Collapsible takes the full width of its container */
    display: flex;
    flex-direction: column; /* Change to column direction to stack trigger and content */
    align-items: center;
     /* Center content horizontally within the Collapsible */
}

.Collapsible__trigger {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    max-width: 80%;
    padding: 15px 10px; /* Adjust padding */
    cursor: pointer;
    border-radius: 20px;
    margin-bottom: 20px; /* Increase margin for all triggers */
    box-shadow: -3.80016px 3.80016px 23.75097px 0px rgba(0, 0, 0, 0.08);
}

.Collapsible__trigger:hover {
    background-image: linear-gradient(90deg, #E4DCFD 0%, #ffffff 100%);
    color: #000000;
}


.collapsible-trigger.selected {
    background-image: linear-gradient(90deg, #E4DCFD 10%, #ffffff 90%); /* Adjust the percentage values as needed */
    color: rgb(132, 18, 198);
    border-radius: 30px; /* You can adjust the value as needed */
}



.Collapsible__contentInner {
    text-align: center;
    padding: 20px;
    border-radius: 20px;
    box-shadow: -3.80016px 3.80016px 23.75097px 0px rgba(0, 0, 0, 0.08);
    margin: 20px;
    max-width: 450px;
    background: linear-gradient(80deg, rgba(255, 255, 255, 0.20) 44.14%, rgba(130, 177, 255, 0.20) 106.5%);
}


.collapsible-icon{
    margin-left: 15px;
}

.mobile-service-content {
    flex: 1;
}

.mobile-service-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
}

.mobile-service-subtitle {
    font-size: 14px;
    margin-bottom: 8px;
}

.mobile-service-description {
    font-size: 14px;
    margin-bottom: 8px;
}

.mobile-service-link {
    text-decoration: none;
}

.mobile-service-button {
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    box-shadow: -3.80016px 3.80016px 23.75097px 0px rgba(0, 0, 0, 0.08);
    background: linear-gradient(90deg, #8c36d8, #7c2cc2); /* Gradient background */
    background-size: 200% 100%; /* Background size for gradient animation */
    transition: color 0.3s, box-shadow 0.3s;
    animation: gradientChange 3s infinite alternate; /* Gradient color change animation */
}

.mobile-service-button:hover {
    color: #f1f1f1; /* Adjust the hover text color as needed */
    box-shadow: -3.80016px 3.80016px 23.75097px 0px rgba(0, 0, 0, 0.15);
}

@keyframes gradientChange {
    0% {
        background-position: 100% 0; /* Start position of gradient */
    }
    100% {
        background-position: -100% 0; /* End position of gradient */
    }
}


.mobile-service-image {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-service-image img {
    max-width: 100%; /* Ensure the image doesn't exceed its container's width */
    height: auto; /* Maintain the aspect ratio of the image */
    border-radius: 10px; /* Add border-radius for a rounded appearance if desired */
   
}

/* Add this CSS to your existing styles */

@media (max-width: 768px) {
    /* Styles for screens up to 768px */

    .mobile-service-container {
        margin-bottom: 30px;
    }

    .mobile-service-heading-container {
        width: 100%;
        border-radius: 50px;
    }

    .mobile-service-heading {
        width: 100%;
        height: auto;
        padding: 15px;
        font-size: 14px; /* Adjust the font size for smaller screens */
        margin-right: 0;
        margin-left: 0;
        border-radius: 50px;
        transition: background-image 0.3s;
    }

    .collapsible-container {
        margin-top: 15px;
    }

    .Collapsible__trigger {
        width: 100%;
        padding: 15px;
        font-size: 16px; /* Adjust the font size for smaller screens */
        margin-bottom: 15px;
    }

    .Collapsible__contentInner {
        margin: 10px;
        font-size: 14px; /* Adjust the font size for smaller screens */
    }

    .collapsible-trigger.selected {
        border-radius: 50px;
    }

    .mobile-service-title {
        font-size: 14px; /* Adjust the font size for smaller screens */
        margin-bottom: 8px;
    }

    .mobile-service-subtitle {
        font-size: 12px; /* Adjust the font size for smaller screens */
        margin-bottom: 8px;
    }

    .mobile-service-description {
        font-size: 12px; /* Adjust the font size for smaller screens */
        margin-bottom: 8px;
    }

    .mobile-service-button {
        font-size: 12px; /* Adjust the font size for smaller screens */
    }
}

@media (max-width: 480px) {
    /* Additional styles for screens up to 480px */

    .mobile-service-heading {
        font-size: 12px;
    }

    .Collapsible__trigger {
        font-size: 14px;
    }

    .mobile-service-title {
        font-size: 12px;
    }

    .mobile-service-subtitle {
        font-size: 10px;
    }

    .mobile-service-description {
        font-size: 10px;
    }

    .mobile-service-button {
        font-size: 10px;
    }
}

@media (max-width: 360px) {
    /* Additional styles for screens up to 360px */

    .mobile-service-heading {
        font-size: 10px;
    }

    .Collapsible__trigger {
        font-size: 12px;
    }

    .mobile-service-title {
        font-size: 10px;
    }

    .mobile-service-subtitle {
        font-size: 8px;
    }

    .mobile-service-description {
        font-size: 8px;
    }

    .mobile-service-button {
        font-size: 8px;
    }
}
