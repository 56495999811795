/* socialMediaFooter.css */

.socialMediaFooter-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  height: auto;
}

.socialMediaFooter-left {
  display: flex;
  align-items: center;
}

.footer-logo {
  width: 150px;
  height: auto;
  margin-right: 20px;
}

.footersocial-icon,
.footer-icon {
  font-size: 24px;
  margin-right: 10px;
  color: #8c36d8;
  transition: transform 0.3s ease, color 0.3s ease;
}

.footersocial-icon:hover,
.footer-icon:hover {
  color: #4d1b6e;
  transform: scale(1.2) rotate(360deg);
}

/* Responsive styles */
@media only screen and (max-width: 768px) {
  .socialMediaFooter-main {
    flex-direction: column;
    text-align: center;
  }

  .socialMediaFooter-left {
    margin-bottom: 20px;
  }

  .footer-logo {
    margin-right: 0;
  }
}
