.linkComponent-main {
    font-family: 'Arial', sans-serif;
    margin: 30px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    
}

.linkComponent-section {
    margin-right: 8%;
    margin-bottom: 30px;
}

.linkComponent-heading {
    color: #333;
    font-size: 44px; /* Adjust the font size as needed */
}

.linkComponent-links {
    list-style-type: none;
    padding: 0;
    margin: 5px 0;
}

.linkComponent-links a {
    text-decoration: none;
    color: #2e2e2e; /* Blue color, you can customize this */
    padding: 8px; /* Adjust padding for better spacing */
    display: inline-block;
    
    font-size: 20px; /* Adjust the font size as needed */
}

.linkComponent-links a:hover {
    color: #8c36d8; /* Darker shade of blue on hover, customize as needed */
}
